<template>
  <v-container fluid class="align-start align-content-start fill-height">
   <booking-payment-action-required-table
     :isLoading="bookingsLoading"
     :bookingsRequirePaymentAction="bookingsRequirePaymentAction"
     @confirmPayment="confirmBookingPayment"
     @cancelBooking="cancelBooking"
     :confirmLoading="confirmLoading"
   ></booking-payment-action-required-table>
    <dialog-wrapper ref="dialogComponent" :width="400" @closeDialog="loadBookingsPaymentRequiresAction">
      <template v-slot:content>
        <cancel-form/>
      </template>
    </dialog-wrapper>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import List from '@/views/shared/list/List.vue';
import ExpandItem from '@/views/shared/list/detail/ExpandItem.vue';
import DialogWrapper from '@/components/elements/DialogWrapper.vue';
import CancelForm from '@/components/elements/CancelForm.vue';
import ErrorTooltip from '../../components/elements/ErrorTooltip.vue';
import BookingPaymentActionRequiredTable from './tables/BookingPaymentActionRequiredTable.vue';

export default {
  name: 'BookingPaymentActionRequired',
  components: {
    CancelForm,
    DialogWrapper,
    ExpandItem,
    List,
    ErrorTooltip,
    BookingPaymentActionRequiredTable },
  mixins: [],
  data() {
    return {
      confirmLoading: false,
    };
  },
  validations: {
    cardName: { required },
    postalCode: { required },
  },
  async mounted() {
    await this.loadBookingsPaymentRequiresAction();
    this.time = setInterval(() => {
      this.loadBookingsPaymentRequiresAction(this.page);
    }, 300000);
  },
  computed: {
    ...mapGetters({
      bookingsRequirePaymentAction: 'bookings/bookingsRequirePaymentAction',
      bookingsLoading: 'bookings/bookingsLoading',
    }),
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async loadBookingsPaymentRequiresAction() {
      await this.$store.dispatch('bookings/listBookingsRequirePaymentAction', {
        filter: ['future'],
        maxPerPage: 10,
        sort: '-pickupTime',
        page: 0,
      });
    },
    async confirmBookingPayment(booking) {
      this.confirmLoading = true;
      const { payment } = booking;
      if (payment.providerClientSecret) {
        await this.$store.dispatch('book/stripePayment3ds', payment.providerClientSecret);
      }
      this.confirmLoading = false;
    },
    async cancelBooking(booking) {
      this.$refs.dialogComponent.openDialog({ id: booking.bookingId, type: 'cancel-booking' });
    },

  },
  beforeDestroy() {
    clearInterval(this.time);
  },

};
</script>

<style scoped>
</style>

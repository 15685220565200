<template>
  <v-card class="py-5 px-8">
    <v-card-title class="pa-0 mb-6">
      {{ $t('components.favourites.form.title') }}

      <v-spacer></v-spacer>

      <v-btn
        @click="closeAction()"
        height="36px"
        icon
        text
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-text-field
      v-model="alias"
      outlined
      filled
      hide-details
      required
      :placeholder="$t('components.favourites.form.namePlaceholder')"
      height="45px"
      class="address-field mb-5"
    ></v-text-field>

    <multiselect
      v-model="address"
      class="address-select mb-5"
      track-by="id"
      label="description"
      open-direction="bottom"
      :show-labels="false"
      :placeholder="$t('components.favourites.form.addressPlaceholder')"
      :show-no-options="false"
      :close-on-select="true"
      :clear-on-select="false"
      :options="suggestions"
      :internal-search="false"
      :searchable="true"
      height="45px"
      @search-change="getAddressSuggestions"
      @select="getAddressDetails($event)"
    >
      <span slot="caret"></span>
    </multiselect>


    <!--    START map  -->
    <div v-show="formattedAddress" style="height: 300px;">
      <GmapMap ref="map" map-type-id="terrain"
                style="height: 100%; width: 100%"
                :center="center"
                :zoom="zoom"
                :options="mapOptions">
        <div>
          <gmap-custom-marker alignment="center" v-for="(point, index) in formattedAddress"
                              :key="`marker-${point.type}-${index}`"
                              :marker="point">
            <map-location :class="`icon-${point.type === 'dropoff' ? 'green' : 'gray'}`" />
          </gmap-custom-marker>
        </div>
      </GmapMap>
    </div>
    <!--    END map  -->

    <v-card-actions class="px-0" :class="suggestions.length && !formattedAddress ? 'dynamic-height': 'mt-5'">
      <v-btn
        class="btn btn-flat"
        text
        @click="closeAction()"
      >
        {{ $t('components.favourites.form.cancel') }}
      </v-btn>

      <v-spacer />

      <v-btn
        class="btn btn-default"
        depressed
        @click="createFavouriteAddress"
      >
      {{ $t('components.favourites.form.continue') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import { iconLoader } from '@/helpers/iconsLoader';
import { gmapApi } from 'vue2-google-maps';

const CENTER = { lat: 0, lng: 0 };
const ZOOM = 2.5;
const MAX_ZOOM = 15;
const MAP_CENTER_OFFSET_X = 538;
const COUNTRY_MAP = {
  ca: [54.0528335, -96.0861555, 5],
  uk: [55.378052, -3.435973, 8],
  gb: [55.378052, -3.435973, 8],
  us: [37.09024, -95.712891, 5],
  au: [-25.274398, 133.775136, 5],
};

export default {
  name: 'add-favourite-form',
  components: {
    GmapCustomMarker,
    ...iconLoader.booking,
  },
  async mounted() {
    this.map = await this.$refs.map.$mapPromise;
    // this.reset();
  },
  data: () => ({
    address: null,
    alias: null,
    formattedAddress: null,
    mapOptions: {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: true,
      keyboardShortcuts: false,
      gestureHandling: 'none',
      clickableIcons: false,
    },
    map: null,
    center: CENTER,
    zoom: ZOOM,
  }),
  watch: {
    alias(val) {
      if (val && val.length) {
        this.alias = val[0].toUpperCase() + val.slice(1);
      }
    },
    formattedAddress(val) {
      if (!val.length) this.reset();
      else this.fitBounds();
    },
  },
  computed: {
    ...mapGetters({
      suggestions: 'geolocation/suggestions',
      sourceState: 'geolocation/source',
      country: 'bookingChannel/defaultCountry',
      defaultLocation: 'bookingChannel/defaultLocation',
    }),
    defaultCenter() {
      if (this.defaultLocation) {
        const { latitude, longitude } = this.defaultLocation;
        return { lat: latitude, lng: longitude };
      }
      const coords = COUNTRY_MAP[this.country];
      if (coords) return { lat: coords[0], lng: coords[1] };
      return CENTER;
    },
    defaultZoom() {
      const coords = this.defaultLocation ? 15 : COUNTRY_MAP[this.country][2];
      if (coords) return coords;
      return ZOOM;
    },
    google: gmapApi,
  },
  methods: {
    reset() {
      // the order of these is important
      this.setZoom(this.defaultZoom);
      this.setCenter(this.defaultCenter);
    },
    setCenter(center) {
      if (!center.lat || !center.lng) return;
      this.map.setCenter({ lat: parseFloat(center.lat), lng: parseFloat(center.lng) });
      if (!center.type) this.map.panBy(0 - MAP_CENTER_OFFSET_X / 2, 0);
    },
    setZoom(zoom) {
      this.zoom = zoom;
      this.map.setZoom(zoom);
    },
    async getAddressSuggestions(search) {
      if (search && search.length > 2) {
        await this.$store.dispatch('geolocation/getSuggestions', { search, lang: this.$i18n.locale });
      }
    },
    fitBounds() {
      if (this.formattedAddress.length === 1) {
        this.setZoom(MAX_ZOOM);
        this.setCenter(this.formattedAddress[0]);
        return;
      }

      const bounds = new this.google.maps.LatLngBounds();

      this.formattedAddress.forEach(point => bounds.extend(point));
      this.map.fitBounds(bounds, {
        left: MAP_CENTER_OFFSET_X + 25 + 115, top: 25, right: 25 + 115, bottom: 25,
      });

      if (this.map.getZoom() > MAX_ZOOM) this.setZoom(MAX_ZOOM);
    },
    async getAddressDetails(address) {
      if (this.sourceState === 'google') {
        const result = await this.$store.dispatch('geolocation/getSuggestionDetail', {
          address,
          type: 'favourites',
          lang: this.$i18n.locale,
        });

        this.formattedAddress = [{
          address: result.description,
          lat: parseFloat(result.coordinates.latitude),
          lng: parseFloat(result.coordinates.longitude),
          locationTypes: result.locationTypes,
          type: 'favourites',
        }];
      } else {
        this.formattedAddress = [{
          address: address.description,
          lat: parseFloat(address.info.coordinates.latitude),
          lng: parseFloat(address.info.coordinates.longitude),
          locationTypes: address.info.locationTypes,
          type: 'favourites',
        }];
      }
    },
    async createFavouriteAddress() {
      if (this.address === null || this.alias === null) {
        console.error({
          type: 'error',
          text: this.$t('components.favourites.form.error', { field: this.address === null ? 'Address' : 'Name' }),
        });
        return;
      }

      const result = await this.$store.dispatch('favourites/createFavouriteAddress', {
        payload: {
          alias: this.alias,
          fullAddressText: this.formattedAddress[0].address,
          latitude: this.formattedAddress[0].lat,
          longitude: this.formattedAddress[0].lng,
          locationTypes: this.formattedAddress[0].locationTypes,
        },
      });

      if (result) {
        this.alias = null;
        this.address = null;
        this.reset();

        this.$emit('addFavourite');
      }
    },
    closeAction() {
      this.alias = null;
      this.address = null;
      this.reset();

      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.dynamic-height {
  margin-top: 300px !important;
}
.address-field .v-text-field input {
  background: initial !important;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
}
.address-select {
  .multiselect__input, .multiselect__single {
    background: initial !important;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    align-items: center;
    letter-spacing: -0.005em;
    color: #333333;

    width: 350px;
    text-overflow: ellipsis !important;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 8px;
  }

  .multiselect__placeholder {
    background: initial !important;

    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: -0.005em;
  }

  .multiselect__element {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.005em;
    color: #333333;
  }

  .multiselect__option--highlight {
    background: #47D061;
  }

  .multiselect__option {
    white-space: normal !important;
  }

  .multiselect__tags {
    background: rgba(0, 0, 0, 0.06);
    border: none !important;
    border-radius: 4px;

    display: flex;
    align-content: center;
    align-items: center;
    height: 45px;
  }

  .multiselect__content {
    padding-left: 0 !important;
  }
}
</style>
